.ttl-1 {
  font-weight: bold;
  letter-spacing: 0.01em;
  font-size: var(--fs-42);
  line-height: 1.2;
  color: var(--b2);
}

@media only screen and (max-width: 991px) {
  .ttl-1 {
    font-size: var(--fs-32);
  }
}

@media only screen and (max-width: 575px) {
  .ttl-1 {
    font-size: var(--fs-24);
  }
}

.ttl-1 .underline {
  position: relative;
}

.ttl-1 .underline::after {
  content: '';
  background-color: var(--y);
  position: absolute;
  left: 0;
  bottom: 10%;
  width: 100%;
  height: 30%;
  z-index: -1;
}

.ttl-2 {
  font-weight: bold;
  font-size: var(--fs-24);
  color: var(--b);
  line-height: 1.2;
  letter-spacing: 0.01em;
}

@media only screen and (max-width: 575px) {
  .ttl-2 {
    font-size: var(--fs-20);
  }
}

.txt-1 {
  font-size: var(--fs-16);
  color: var(--b2);
  line-height: 1.5;
  letter-spacing: 0.01em;
}

.rating_star_col {
  display: flex;
  flex-direction: column;
}

.rating_star_row {
  width: 100%;
  display: flex;
}

.rating_star_row_first {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

@media screen and (max-width: 666px) {
  .rating_star_row_first {
    flex-direction: column;
    margin-bottom: 20px;
  }
}

.txt-2 {
  font-weight: bold;
  font-size: var(--fs-18);
  line-height: 1.2;
  color: var(--b2);
  letter-spacing: 0.01em;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  display: block;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 12px;
  width: 12px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li.slick-active button {
  background: var(--y);
}

.slick-dots li button {
  border: 0;
  background: #E7E7E7;
  display: block;
  height: 12px;
  width: 12px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  cursor: pointer;
  padding: 0;
  border-radius: 50%;
}

.slick-prev,
.slick-next {
  position: absolute;
  background: #FFFFFF;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15), 0px 4px 2px rgba(0, 0, 0, 0.05);
  display: block;
  height: 52px;
  width: 52px;
  line-height: 0px;
  font-size: 0px;
  border-radius: 50%;
  cursor: pointer;
  color: transparent;
  top: -55px;
  left: unset;
  right: 153px;
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev::before,
.slick-next::before {
  content: '';
  width: 14px;
  height: 24px;
  background: url(../images/icon/big-arrow.svg) center center no-repeat;
  background-size: contain;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}

.slick-prev {
  right: 230px;
}

.slick-prev::before {
  transform: translate(-50%, -50%) rotate(-180deg);
}

.article-back {
  text-decoration: none;
  font-size: var(--fs-20);
  position: relative;
  padding-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
  visibility: hidden;
  opacity: 0;
}

@media only screen and (max-width: 991px) {
  .article-back {
    visibility: unset;
    opacity: 1;
  }
}

.article-back::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(-180deg);
  left: 0;
  background-image: url("../images/icon/breadcrumbs-arr.svg");
  background-repeat: no-repeat;
  width: 8px;
  height: 14px;
  background-size: contain;
}

.breadcrumbs {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media only screen and (max-width: 991px) {
  .breadcrumbs {
    margin-top: 20px;
  }
}

.breadcrumbs a {
  color: black;
  font-size: var(--fs-16);
  line-height: 1.2;
  text-decoration: none;
}

@media only screen and (max-width: 575px) {
  .breadcrumbs a {
    font-size: var(--fs);
  }
}

@media only screen and (max-width: 374px) {
  .breadcrumbs a {
    font-size: 11px;
  }
}

.breadcrumbs-arr {
  margin: 3px 5px 0 7px;
  width: 7px;
  height: 10px;
  background-image: url("../images/icon/breadcrumbs-arr.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

@media only screen and (max-width: 575px) {
  .breadcrumbs-arr {
    margin: 2px 3px 0 3px;
  }
}

.pagination {
  display: flex;
  align-items: center;
}

.pagination-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  text-decoration: none;
  background-color: #FFFFFF;
  border: 1px solid #F1F1F1;
  font-weight: bold;
  font-size: var(--fs-18);
  color: var(--b2);
  line-height: 1.3;
  letter-spacing: 0.01em;
  position: relative;
  transition: all ease .3s;
}

.pagination-link:hover {
  background-color: var(--y);
  border-color: var(--y);
}

.pagination-link:first-of-type::after, .pagination-link:last-of-type::after {
  content: "";
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  left: 61%;
  transform: translate(-50%, -50%) rotate(-225deg);
  border-right: 2px solid black;
  border-bottom: 2px solid black;
}

.pagination-link:last-of-type::after {
  top: 50%;
  left: 41%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.contact-baner__wrap {
  background-color: var(--y);
}

.contact-baner {
  padding-top: 83px;
  padding-bottom: 76px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .contact-baner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: unset;
  }
}

.contact-baner .contact-baner__img {
  position: absolute;
  bottom: 0;
  right: 14%;
}

@media only screen and (max-width: 1023px) {
  .contact-baner .contact-baner__img {
    right: 5%;
  }
}

@media only screen and (max-width: 991px) {
  .contact-baner .contact-baner__img {
    width: 100%;
    max-width: 340px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-baner .contact-baner__img {
    position: relative;
    bottom: unset;
    right: unset;
  }
}

.contact-baner__ttl {
  width: 100%;
  max-width: 549px;
  margin-bottom: 43px;
}

@media only screen and (max-width: 991px) {
  .contact-baner__ttl {
    max-width: 380px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-baner__ttl {
    max-width: 270px;
    text-align: center;
  }
}

.contact-baner__link {
  width: 259px;
  min-height: 52px;
  font-weight: bold;
  font-size: var(--fs-18);
  line-height: 1.3;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000;
}

@media only screen and (max-width: 767px) {
  .contact-baner__link {
    margin-bottom: 60px;
  }
}

.blog .blog-block {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 53px;
  position: relative;
  height: 660px;
}

@media only screen and (max-width: 1439px) {
  .blog .blog-block {
    flex-direction: column;
    height: unset;
  }
}

@media only screen and (max-width: 991px) {
  .blog .blog-block {
    margin-top: 30px;
  }
}

.blog .blog-block:last-of-type {
  margin-bottom: 60px;
}

@media only screen and (max-width: 991px) {
  .blog .blog-block:last-of-type {
    margin-bottom: 30px;
  }
}

.blog .blog-block__info {
  width: 100%;
  max-width: 356px;
  margin-right: 31px;
}

@media only screen and (max-width: 1439px) {
  .blog .blog-block__info {
    margin-right: 0;
    max-width: 100%;
  }
}

.blog .blog-block__info .info-ttl {
  margin-bottom: 26px;
}

@media only screen and (max-width: 991px) {
  .blog .blog-block__info .info-ttl {
    margin-bottom: 10px;
  }
}

.blog .blog-block__slider {
  margin-top: 82px;
  width: 900px;
  height: 564.6px;
  position: absolute;
  top: 0;
  right: -149px;
}

@media only screen and (max-width: 1439px) {
  .blog .blog-block__slider {
    align-self: center;
    position: relative;
    right: unset;
    top: unset;
    height: unset;
  }
}

@media only screen and (max-width: 991px) {
  .blog .blog-block__slider {
    width: 390px;
  }
}

@media only screen and (max-width: 767px) {
  .blog .blog-block__slider {
    margin-top: 30px;
    width: 390px;
  }
}

@media only screen and (max-width: 423px) {
  .blog .blog-block__slider {
    width: 290px;
  }
}

.blog .blog-block__slider .blog-slider {
  width: 900px;
  height: 564.6px;
}

@media only screen and (max-width: 1439px) {
  .blog .blog-block__slider .blog-slider {
    height: unset;
  }
}

@media only screen and (max-width: 991px) {
  .blog .blog-block__slider .blog-slider {
    width: 390px;
  }
}

@media only screen and (max-width: 767px) {
  .blog .blog-block__slider .blog-slider {
    width: 390px;
  }
}

@media only screen and (max-width: 423px) {
  .blog .blog-block__slider .blog-slider {
    width: 290px;
  }
}

.blog .blog-block__slider .blog-slider .slick-list {
  padding: 0 13% 30px 0;
}

@media only screen and (max-width: 991px) {
  .blog .blog-block__slider .blog-slider .slick-list {
    padding: 0 5% 30px 0;
  }
}

.blog .blog-block__slider .blog-slider__slide {
  display: block;
  width: 100%;
  max-width: 357px;
  background: #fff;
  box-shadow: 4px 7px 20px rgba(0, 0, 0, 0.1);
  text-decoration: none;
}

@media only screen and (max-width: 991px) {
  .blog .blog-block__slider .blog-slider__slide {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 423px) {
  .blog .blog-block__slider .blog-slider__slide {
    max-width: unset;
    width: 267px;
  }
}

.blog .blog-block__slider .blog-slider__slide .slide-img__wrap {
  width: 100%;
  height: 244px;
}

@media only screen and (max-width: 423px) {
  .blog .blog-block__slider .blog-slider__slide .slide-img__wrap {
    height: unset;
  }
}

.blog .blog-block__slider .blog-slider__slide .slide-desc {
  margin: 0 auto;
  padding: 20px 27px 42px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.blog .blog-block__slider .blog-slider__slide .slide-desc__date {
  opacity: .8;
  margin-bottom: 7px;
}

.blog .blog-block__slider .blog-slider__slide .slide-desc__ttl {
  margin-bottom: 20px;
  max-width: 286px;
}

.blog .blog-block__slider .blog-slider .last-slide {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 230px;
  margin-left: 44px;
  max-width: 286px;
}

@media only screen and (max-width: 991px) {
  .blog .blog-block__slider .blog-slider .last-slide {
    margin: 230px auto 0 auto;
    align-items: center;
  }
}

@media only screen and (max-width: 423px) {
  .blog .blog-block__slider .blog-slider .last-slide {
    max-width: 267px;
  }
}

.blog .blog-block__slider .blog-slider .last-slide__txt {
  margin-bottom: 6px;
}

.blog .blog-block__slider .blog-slider .last-slide__link {
  text-decoration: underline;
  text-decoration-color: #F8CE4C;
}

@media only screen and (max-width: 991px) {
  .blog .blog-block__slider .blog-slider .last-slide__link {
    text-align: center;
  }
}

.article-same {
  margin-bottom: 95px;
}

@media only screen and (max-width: 991px) {
  .article-same {
    margin-bottom: 30px;
  }
}

.article-same__ttl {
  text-align: center;
  margin-bottom: 60px;
}

@media only screen and (max-width: 991px) {
  .article-same__ttl {
    margin-bottom: 20px;
  }
}

.article-same__slider .slick-list {
  padding-bottom: 30px;
}

.article-same__slider .slick-dots {
  bottom: -5px;
}

.article-same__slider .news-block {
  display: block;
  width: 100%;
  max-width: 357px;
  background: #FFFFFF;
  box-shadow: 2px 6px 25px rgba(0, 0, 0, 0.08), 0px 2px 2px rgba(0, 0, 0, 0.05);
  text-decoration: none;
}

@media only screen and (max-width: 1199px) {
  .article-same__slider .news-block {
    max-width: 300px;
  }
}

@media only screen and (max-width: 991px) {
  .article-same__slider .news-block {
    width: 357px;
    margin: 0 auto;
  }
}

.article-same__slider .news-block .news-img__wrap {
  width: 100%;
  min-height: 282px;
}

@media only screen and (max-width: 1199px) {
  .article-same__slider .news-block .news-img__wrap {
    min-height: unset;
  }
}

.article-same__slider .news-block__description {
  padding: 20px  36px 24px 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.article-same__slider .news-block__description .description-date {
  opacity: 0.8;
  margin-bottom: 10px;
}

.article_container {
  max-width: 748px;
  width: 100%;
  height: 100%;
  margin: 25px auto 35px auto;
}

.article_container .article_img_container {
  max-width: 748px;
  width: 100%;
}

.article_container .article_img_container .article_img_second_row {
  max-width: 748px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 0 0 10px 0;
}

@media only screen and (max-width: 546px) {
  .article_container .article_img_container .article_img_second_row {
    flex-wrap: wrap;
  }
}

.article_container .article_img_container .article_img_second_row .article_img {
  display: block;
  height: 100%;
  object-fit: contain;
  max-height: 234px;
  padding: 0 5px;
}

@media only screen and (max-width: 546px) {
  .article_container .article_img_container .article_img_second_row .article_img {
    object-fit: cover;
  }
}

.article_container .article_img_container .article_img_second_row .half_screen_img {
  width: 50%;
}

@media only screen and (max-width: 546px) {
  .article_container .article_img_container .article_img_second_row .half_screen_img {
    width: 100%;
    padding: 5px;
  }
}

.article_container .article_img_container .article_img_second_row .fourth_screen_img {
  width: 25%;
}

@media only screen and (max-width: 546px) {
  .article_container .article_img_container .article_img_second_row .fourth_screen_img {
    width: 50%;
    padding: 5px;
  }
}

@media only screen and (max-width: 546px) {
  .article_container .article_img_container .fourth_img_row {
    justify-content: center;
  }
  .article_container .article_img_container .fourth_img_row .article_img {
    object-fit: cover;
  }
}

.more-blocks {
  margin-top: 40px;
  margin-bottom: 80px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media only screen and (max-width: 991px) {
  .more-blocks {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .more-blocks {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

.more-blocks .block-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .more-blocks .block-left {
    margin-right: 0;
    margin-bottom: 40px;
  }
}

.more-blocks .block-left .left-news {
  text-decoration: none;
  display: block;
  width: 100%;
  max-width: 649px;
  background: #FFFFFF;
  box-shadow: 2px 6px 25px rgba(0, 0, 0, 0.08), 0px 2px 2px rgba(0, 0, 0, 0.05);
}

@media only screen and (max-width: 1199px) {
  .more-blocks .block-left .left-news {
    max-width: 570px;
  }
}

@media only screen and (max-width: 991px) {
  .more-blocks .block-left .left-news {
    max-width: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .more-blocks .block-left .left-news {
    max-width: 357px;
  }
}

.more-blocks .block-left .left-news:not(:last-of-type) {
  margin-bottom: 30px;
}

.more-blocks .block-left .left-news__wrap {
  width: 100%;
}

.more-blocks .block-left .left-news__desc {
  padding: 30px;
}

@media only screen and (max-width: 767px) {
  .more-blocks .block-left .left-news__desc {
    padding: 24px;
  }
}

.more-blocks .block-left .left-news__desc .desc-date {
  opacity: .8;
  margin-bottom: 10px;
  display: block;
}

.more-blocks .block-left .left-news__desc .desc-ttl {
  margin-bottom: 25px;
}

.more-blocks .block-left .more-pagination {
  display: none;
}

@media only screen and (max-width: 767px) {
  .more-blocks .block-left .more-pagination {
    display: flex;
    align-self: center;
    margin-top: 30px;
  }
}

.more-blocks .block-right .right-cat {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 60px;
}

@media only screen and (max-width: 991px) {
  .more-blocks .block-right .right-cat {
    margin-bottom: 30px;
  }
}

.more-blocks .block-right .right-cat__ttl {
  margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
  .more-blocks .block-right .right-cat__ttl {
    margin-bottom: 20px;
  }
}

.more-blocks .block-right .right-cat__link {
  text-decoration: none;
}

.more-blocks .block-right .right-cat__link:not(:last-of-type) {
  margin-bottom: 15px;
}

.more-blocks .block-right .right-popular {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.more-blocks .block-right .right-popular__ttl {
  margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
  .more-blocks .block-right .right-popular__ttl {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .more-blocks .block-right .right-popular__slider {
    width: 100%;
    max-width: 375px;
  }
}

@media only screen and (max-width: 423px) {
  .more-blocks .block-right .right-popular__slider {
    max-width: 290px;
  }
}

.more-blocks .block-right .right-popular__slider .slick-list {
  padding-bottom: 20px;
}

.more-blocks .block-right .right-popular__slider > div:not(:last-of-type) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .more-blocks .block-right .right-popular__slider > div:not(:last-of-type) {
    margin-bottom: 0;
  }
}

.more-blocks .block-right .right-popular__news {
  display: block;
  text-decoration: none;
  width: 100%;
  max-width: 357px;
  background: #FFFFFF;
  box-shadow: 4px 7px 20px rgba(0, 0, 0, 0.1);
}

.more-blocks .block-right .right-popular__news .popular-wrap {
  width: 100%;
}

.more-blocks .block-right .right-popular__news .popular-desc {
  padding: 20px 47px 25px 24px;
}

.more-blocks .block-right .right-popular__news .popular-desc__date {
  display: block;
  margin-bottom: 7px;
}

.testimonials {
  margin-top: 66px;
  margin-bottom: 79px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 991px) {
  .testimonials {
    margin-top: 30px;
    margin-bottom: 60px;
  }
}

.testimonials-block {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  max-width: 843px;
  background: #FFFFFF;
  box-shadow: 4px 7px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

@media only screen and (max-width: 575px) {
  .testimonials-block {
    flex-direction: column;
    max-width: 320px;
  }
}

.testimonials-block:last-of-type {
  margin-bottom: 44px;
}

@media only screen and (max-width: 991px) {
  .testimonials-block:last-of-type {
    margin-bottom: 30px;
  }
}

.testimonials-block__wrap {
  width: 100%;
  max-width: 261px;
  min-height: 287px;
}

@media only screen and (max-width: 575px) {
  .testimonials-block__wrap {
    max-width: unset;
    min-height: unset;
    height: 250px;
  }
}

.testimonials-block__desc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  width: 100%;
  max-width: 582px;
}

.testimonials-block__desc .desc-name {
  font-weight: bold;
  font-size: var(--fs-18);
  line-height: 1.3;
  color: var(--b2);
  margin-bottom: 15px;
}

.testimonials-block__desc .desc-auto {
  opacity: .8;
  margin-bottom: 3px;
}

.testimonials-block__desc .desc-auto span {
  opacity: 1;
  font-weight: bold;
}

.testimonials-block__desc .desc-budget {
  opacity: .8;
  margin-bottom: 25px;
}

.testimonials-block__desc .desc-budget span {
  opacity: 1;
  font-weight: bold;
}

.testimonials-block__desc .desc-testimonial {
  margin-bottom: 10px;
}

.testimonials-block__desc .desc-more {
  text-decoration: none;
  font-weight: bold;
  font-size: var(--fs-18);
  line-height: 1.4;
  letter-spacing: 0.01em;
}

.contacts-ttl {
  margin-top: 37px;
  margin-bottom: 50px;
  text-align: center;
}

@media only screen and (max-width: 991px) {
  .contacts-ttl {
    margin-top: 20px;
    margin-bottom: 40px;
  }
}

.contacts-location {
  margin-bottom: 32px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media only screen and (max-width: 991px) {
  .contacts-location {
    margin-bottom: 5px;
  }
}

.contacts-location__name {
  display: block;
  position: relative;
  padding-left: 30px;
  width: 100%;
  max-width: 25%;
  margin-bottom: 25px;
}

@media only screen and (max-width: 991px) {
  .contacts-location__name {
    max-width: 33%;
  }
}

@media only screen and (max-width: 767px) {
  .contacts-location__name {
    font-size: var(--fs);
  }
}

@media only screen and (max-width: 575px) {
  .contacts-location__name {
    max-width: 50%;
  }
}

.contacts-location__name::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-image: url("../images/icon/contacts-marker.png");
  background-repeat: no-repeat;
  width: 21px;
  height: 30px;
}

.contacts-map {
  width: 100%;
  position: relative;
}

@media only screen and (max-width: 991px) {
  .contacts-map {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.contacts-map #map {
  width: 100%;
  height: 573px;
}

@media only screen and (max-width: 991px) {
  .contacts-map #map {
    order: 2;
    height: 400px;
  }
}

@media only screen and (max-width: 575px) {
  .contacts-map #map {
    height: 350px;
  }
}

.contacts-map__address {
  position: absolute;
  top: 8.3%;
  right: 8.8%;
  width: 100%;
  max-width: 455px;
  min-height: 474px;
  background-color: var(--b2);
}

@media only screen and (max-width: 1199px) {
  .contacts-map__address {
    top: 8%;
    right: 2%;
  }
}

@media only screen and (max-width: 991px) {
  .contacts-map__address {
    position: relative;
    right: auto;
    left: auto;
    top: auto;
    transform: unset;
    min-height: auto;
  }
}

.contacts-map__address .address-nav {
  display: flex;
}

.contacts-map__address .address-nav__link {
  text-decoration: none;
  width: 50%;
  padding: 14px 58px 13px 58px;
  font-weight: bold;
  font-size: var(--fs-24);
  line-height: 1;
  text-align: center;
  letter-spacing: 0.01em;
  color: #fff;
  background-color: var(--b2);
  transition: all .4s ease;
}

@media only screen and (max-width: 575px) {
  .contacts-map__address .address-nav__link {
    font-size: var(--fs-16);
    padding: 14px 10px 13px 10px;
  }
}

.contacts-map__address .address-nav__link:hover {
  background-color: var(--y);
  color: var(--b2);
}

.contacts-map__address .address-nav__link.tab-active {
  background-color: var(--y);
  color: var(--b2);
}

.contacts-map__address .address-nav__link.tab-active.one-tab {
  width: 100%;
}

.contacts-map__address .address-stage {
  padding: 40px 29px 29px;
}

.contacts-map__address .address-stage div {
  flex-direction: column;
}

.contacts-map__address .address-stage .address-stage__map {
  margin-bottom: 12px;
  font-weight: bold;
  font-size: var(--fs-18);
  line-height: 24px;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
}

@media only screen and (max-width: 575px) {
  .contacts-map__address .address-stage .address-stage__map {
    font-size: 14px;
    line-height: 16px;
  }
}

.contacts-map__address .address-stage .address-stage__time {
  margin-bottom: 30px;
  font-size: var(--fs-16);
  line-height: 24px;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.9);
}

@media only screen and (max-width: 575px) {
  .contacts-map__address .address-stage .address-stage__time {
    font-size: 14px;
    line-height: 16px;
  }
}

.contacts-map__address .address-stage .address-phone__block {
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
}

.contacts-map__address .address-stage .address-phone__block .address-stage__phone {
  font-weight: bold;
  font-size: var(--fs-18);
  line-height: 24px;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
}

.contacts-map__address .address-stage .address-phone__block .address-stage__phone:not(:last-of-type) {
  margin-bottom: 10px;
}

@media only screen and (max-width: 575px) {
  .contacts-map__address .address-stage .address-phone__block .address-stage__phone {
    font-size: 14px;
    line-height: 16px;
  }
}

.contacts-map__address .address-stage .address-mail__block {
  display: flex;
  flex-direction: column;
}

.contacts-map__address .address-stage .address-mail__block .address-stage__mail {
  font-weight: bold;
  font-size: var(--fs-18);
  line-height: 24px;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
}

.contacts-map__address .address-stage .address-mail__block .address-stage__mail:not(:last-of-type) {
  margin-bottom: 10px;
}

@media only screen and (max-width: 575px) {
  .contacts-map__address .address-stage .address-mail__block .address-stage__mail {
    font-size: 14px;
    line-height: 16px;
  }
}

.contacts-map__address .tabs-stage div p {
  color: #fff;
}

.contacts-map__address .address-img__wrap {
  width: 222px;
  height: 143px;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media only screen and (max-width: 575px) {
  .contacts-map__address .address-img__wrap {
    width: 155px;
    height: 100px;
  }
}

.contacts-map__address .address-img__wrap .address-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: 50% 50%;
  display: block;
}

.contacts-info {
  display: flex;
  flex-wrap: wrap;
  margin-top: 79px;
  margin-bottom: 80px;
}

@media only screen and (max-width: 991px) {
  .contacts-info {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .contacts-info {
    flex-wrap: unset;
    flex-direction: column;
    align-items: center;
  }
}

.contacts-info__vacancies {
  width: 50%;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 575px) {
  .contacts-info__vacancies {
    margin-bottom: 30px;
    width: 100%;
    max-width: 323px;
  }
}

.contacts-info__vacancies .vacancies-ttl {
  margin-bottom: 10px;
}

.contacts-info__vacancies .vacancies-subttl {
  margin-bottom: 20px;
  font-size: var(--fs-16);
  line-height: 24px;
  letter-spacing: 0.01em;
  color: rgba(28, 28, 27, 0.8);
}

.contacts-info__vacancies .vacancies-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.contacts-info__vacancies .vacancies-list__item {
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: rgba(28, 28, 27, 0.9);
  position: relative;
  padding-left: 18px;
}

.contacts-info__vacancies .vacancies-list__item::after {
  content: "";
  position: absolute;
  top: 12px;
  left: 0;
  width: 8px;
  height: 8px;
  background: #FFCE1B;
  border-radius: 50%;
}

.contacts-info__vacancies .vacancies-list__item:not(:last-of-type) {
  margin-bottom: 5px;
}

.contacts-info__contact {
  width: 50%;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 575px) {
  .contacts-info__contact {
    width: unset;
  }
}

.contacts-info__contact .contact-ttl {
  margin-bottom: 30px;
  width: 100%;
  max-width: 323px;
}

@media only screen and (max-width: 575px) {
  .contacts-info__contact .contact-ttl {
    margin-bottom: 20px;
  }
}

.contacts-info__contact .contact-link {
  text-decoration: none;
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: rgba(28, 28, 27, 0.9);
}

.contacts-info__contact .contact-link:not(:last-of-type) {
  margin-bottom: 6px;
}

.contacts-info__franchising {
  width: 100%;
  max-width: 748px;
  margin: 80px auto 0 auto;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 991px) {
  .contacts-info__franchising {
    margin-top: 40px;
  }
}

.contacts-info__franchising .franchising-ttl {
  text-align: center;
  margin-bottom: 40px;
}

@media only screen and (max-width: 575px) {
  .contacts-info__franchising .franchising-ttl {
    margin-bottom: 20px;
  }
}

.contacts-info__franchising h1, .contacts-info__franchising h2, .contacts-info__franchising h3 {
  margin: 20px 0  40px 0;
}

@media only screen and (max-width: 575px) {
  .contacts-info__franchising h1, .contacts-info__franchising h2, .contacts-info__franchising h3 {
    margin: 10px 0  20px 0;
  }
}

.contacts-info__franchising h4, .contacts-info__franchising h5, .contacts-info__franchising h6 {
  margin: 20px 0  20px 0;
}

@media only screen and (max-width: 575px) {
  .contacts-info__franchising h4, .contacts-info__franchising h5, .contacts-info__franchising h6 {
    margin: 10px 0  15px 0;
  }
}

.contacts-info__franchising h1, .contacts-info__franchising h2, .contacts-info__franchising h3, .contacts-info__franchising h4, .contacts-info__franchising h5, .contacts-info__franchising h6 {
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.01em;
  line-height: 1.2;
  color: var(--b2);
}

.contacts-info__franchising h1 {
  font-size: var(--fs-46);
}

.contacts-info__franchising h2 {
  font-size: var(--fs-42);
}

.contacts-info__franchising h3 {
  font-size: var(--fs-37);
}

.contacts-info__franchising h4 {
  font-size: var(--fs-32);
}

.contacts-info__franchising h5 {
  font-size: var(--fs-28);
}

.contacts-info__franchising h6 {
  font-size: var(--fs-24);
}

.contacts-info__franchising p {
  font-size: var(--fs-16);
  color: var(--b2);
  line-height: 1.5;
  letter-spacing: 0.01em;
  margin: 5px 0 30px 0;
}

@media only screen and (max-width: 575px) {
  .contacts-info__franchising p {
    margin: 5px 0 20px 0;
  }
}

.contacts-info__franchising .franchising-contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contacts-info__franchising .franchising-contact__ttl {
  margin-bottom: 20px;
}

.contacts-info__franchising .franchising-contact__link {
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: rgba(28, 28, 27, 0.9);
}

.contacts-info__franchising .franchising-contact__link:not(:last-of-type) {
  margin-bottom: 6px;
}

.database-ttl {
  margin-top: 37px;
  margin-bottom: 50px;
  text-align: center;
}

.database-subttl {
  margin-bottom: 7px;
}

.database-info {
  width: 100%;
  max-width: 748px;
  margin-bottom: 20px;
}

.database-link {
  display: inline-block;
  margin-bottom: 40px;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #1B5BFF;
}

.database-steps {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 60px;
}

@media only screen and (max-width: 767px) {
  .database-steps {
    flex-wrap: wrap;
  }
}

.database-steps__step {
  width: 33%;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .database-steps__step {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .database-steps__step:not(:last-child) {
    margin-bottom: 20px;
  }
}

.database-steps__step .step-number {
  width: 31px;
  height: 31px;
  background-color: #FFCE1B;
  border-radius: 50%;
  margin-right: 16px;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #242424;
  padding: 4px 8px 3px 9px;
}

.database-steps__step .step-txt {
  margin-top: 3px;
}

.database-steps__step .step-block {
  margin-top: 3px;
}

.database-steps__step .step-block .step-list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 5px;
}

.database-steps__step .step-block .step-list__item {
  padding-left: 13px;
  position: relative;
}

.database-steps__step .step-block .step-list__item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 9px;
  width: 6px;
  height: 6px;
  background-color: #FFCE1B;
  border-radius: 50%;
}

.database-video {
  width: 100%;
  max-width: 551px;
  height: 310px;
  margin: 0 auto 60px auto;
  position: relative;
}

.database-video::before {
  content: "";
  display: block;
  padding-top: 56.25%;
}

.database-video__iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.database-codes {
  margin-bottom: 70px;
}

.database-vin {
  margin-bottom: 131px;
}

.database-check__ttl {
  margin-bottom: 7px;
}

.database-check__txt {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 748px;
}

.database-check__txt:last-of-type {
  margin-bottom: 30px;
}

.database-check__txt img {
  width: 100%;
  max-width: 194px;
  margin-left: 10px;
}

@media only screen and (max-width: 575px) {
  .database-check__txt img {
    max-width: 175px;
  }
}

.database-check {
  width: 100%;
  max-width: 342px;
  min-height: 330px;
  background-color: #FFFFFF;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  padding: 30px;
  display: flex;
  flex-direction: column;
}

.database-check .check-ttl {
  margin-bottom: 22px;
}

.database-check .check-nav {
  margin-bottom: 25px;
  display: flex;
}

.database-check .check-nav__link {
  text-align: center;
  text-decoration: none;
  width: 50%;
  padding: 15px 20px 14px 20px;
  background: #FFFFFF;
  transition: all .3s ease;
  font-size: 18px;
  line-height: 21px;
  color: #242424;
}

.database-check .check-nav__link:hover {
  background-color: #FFCE1B;
}

.database-check .check-nav__link:hover:first-of-type {
  border-left: 1px solid #FFCE1B;
  border-top: 1px solid #FFCE1B;
  border-bottom: 1px solid #FFCE1B;
}

.database-check .check-nav__link:hover:last-of-type {
  border-right: 1px solid #FFCE1B;
  border-top: 1px solid #FFCE1B;
  border-bottom: 1px solid #FFCE1B;
}

.database-check .check-nav__link.tab-active {
  background-color: #FFCE1B;
}

.database-check .check-nav__link.tab-active:first-of-type {
  border-left: 1px solid #FFCE1B;
  border-top: 1px solid #FFCE1B;
  border-bottom: 1px solid #FFCE1B;
}

.database-check .check-nav__link.tab-active:last-of-type {
  border-right: 1px solid #FFCE1B;
  border-top: 1px solid #FFCE1B;
  border-bottom: 1px solid #FFCE1B;
}

.database-check .check-nav__link:first-of-type {
  border-left: 1px solid #939393;
  border-top: 1px solid #939393;
  border-bottom: 1px solid #939393;
}

.database-check .check-nav__link:last-of-type {
  border-right: 1px solid #939393;
  border-top: 1px solid #939393;
  border-bottom: 1px solid #939393;
}

.database-check .check-stage {
  margin-bottom: 30px;
}

.database-check .check-btn {
  border: none;
  height: 44px;
  background-color: #FFCE1B;
  padding: 12px 20px 13px 20px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  transition: all .3s ease;
}

.database-check .check-btn:disabled:hover {
  opacity: 1;
}

.database-check .check-btn:hover {
  opacity: .85;
}

.check-inp {
  width: 100%;
  height: 42px;
  background-color: #FFFFFF;
  border: 1px solid #939393;
  padding: 11px;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
}

.check-inp::placeholder {
  color: #939393;
}

.database-result {
  margin-top: 82px;
  margin-bottom: 127px;
}

@media only screen and (max-width: 1440px) {
  .database-result {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .database-result {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.database-result__ttl {
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  color: #242424;
  margin-bottom: 7px;
}

.database-result__desc {
  width: 100%;
  max-width: 748px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #1C1C1B;
  margin-bottom: 50px;
}

.database-result__autos {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1199px) {
  .database-result__autos {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 991px) {
  .database-result__autos {
    justify-content: space-between;
  }
}

@media only screen and (max-width: 575px) {
  .database-result__autos {
    justify-content: center;
  }
}

.database-result__autos .auto-card {
  width: 100%;
  max-width: 256px;
  background: #FFFFFF;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1), 0px 3px 2px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  .database-result__autos .auto-card {
    max-width: calc(33% - 38px);
  }
}

@media only screen and (max-width: 991px) {
  .database-result__autos .auto-card {
    max-width: calc(50% - 20px);
  }
}

@media only screen and (max-width: 575px) {
  .database-result__autos .auto-card {
    max-width: 309px;
  }
}

.database-result__autos .auto-card:not(:nth-of-type(4n)) {
  margin-right: 38px;
}

@media only screen and (max-width: 1199px) {
  .database-result__autos .auto-card:not(:nth-of-type(4n)) {
    margin-right: unset;
  }
}

@media only screen and (max-width: 1199px) {
  .database-result__autos .auto-card:not(:nth-of-type(3n)) {
    margin-right: 38px;
  }
}

@media only screen and (max-width: 991px) {
  .database-result__autos .auto-card:not(:nth-of-type(3n)) {
    margin-right: unset;
  }
}

@media only screen and (max-width: 991px) {
  .database-result__autos .auto-card:not(:nth-of-type(2n)) {
    margin-right: 38px;
  }
}

@media only screen and (max-width: 575px) {
  .database-result__autos .auto-card:not(:nth-of-type(2n)) {
    margin-right: unset;
  }
}

.database-result__autos .auto-card__link {
  text-decoration: none;
}

.database-result__autos .auto-card__wrap {
  width: 100%;
  height: 180px;
  position: relative;
}

.database-result__autos .auto-card__wrap .car-recomend {
  position: absolute;
  top: 20px;
  right: 10px;
  background: #0CB15E;
  font-size: 12px;
  line-height: 14px;
  color: #FFFFFF;
  padding: 5px 12px;
}

.database-result__autos .auto-card__info {
  padding: 20px 27px 30px 22px;
}

.database-result__autos .auto-card__info .info-car__name {
  display: block;
  margin-bottom: 10px;
}

.database-result__autos .auto-card__info .info-car__characteristics {
  display: block;
  margin-bottom: 31px;
}

.database-result__choose {
  display: flex;
  align-items: center;
  justify-content: center;
}

.database-result__choose .choose-btn {
  padding: 12px 33px 13px 33px;
  background: #FFCE1B;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #242424;
  text-decoration: none;
  transition: all .3s ease;
}

.database-result__choose .choose-btn:hover {
  opacity: 0.85;
  box-shadow: none;
}
